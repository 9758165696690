<template>
    <div class="explore-btn chevron-container">
        <div class="chevron" :class="{'gold': isGold}"></div>
        <div class="chevron" :class="{'gold': isGold}"></div>
        <div class="chevron" :class="{'gold': isGold}"></div>
    </div>
</template>

<script>
export default {
    name: 'DChevron',
    props: ['isGold','rotateDeg'],
}
</script>

<style lang="sass" scoped>
.chevron-container
  min-width: 5px * 3.5
.chevron 
  position: absolute
  top: 10px
  width: 5px * 3.5
  height: 5px * 0.8
  opacity: 0
  transform: scale(0.3)
  animation: move-chevron 3s ease-out infinite
.chevron:first-child 
  animation: move-chevron 3s ease-out 1s infinite

.chevron:nth-child(2)
  animation: move-chevron 3s ease-out 2s infinite

.chevron:before, .chevron:after
 content: ''
 position: absolute
 top: 0
 height: 100%
 width: 50%
 background: #6639ED

.chevron
  &.gold:before, &.gold:after
    background: #FFB754
.chevron:before
 left: 0
 transform: skewY(30deg)

.chevron:after
 right: 0
 width: 50%
 transform: skewY(-30deg)

@keyframes move-chevron
 25%
  opacity: 1
 33.3%
  opacity: 1
  transform: translateY(5px * 3.8)
 66.6%
  opacity: 1
  transform: translateY(5px * 5.2)
 100%
  opacity: 0
  transform: translateY(5px * 8) scale(0.5)

@media (max-width: 1100px)
  .chevron-container
    transform: scale(0.6) !important
</style>